import React, { Component } from "react"
import { BounceLoader } from "react-spinners"
import { AnimatePresence, motion } from "framer-motion"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"

export class contact extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
    }
  }

  hideSpinner = () => {
    this.setState({ isLoading: false })
  }

  render() {
    return (
      <Layout>
        <SEO title="Contact" description="Get in Touch" />

        <div className="mb-14 md:mb-28 mx-auto md:w-full max-w-180 min-h-screen">
          <div className="mt-6 md:mt-23 mx-10 md:mx-auto mb-14 md:mb-15 ">
            <h1 className="text-h3 md:text-h1 font-heading font-bold text-center text-black">
              Get in Touch
            </h1>
          </div>

          <div className="relative mx-5 md:mx-auto">
            <AnimatePresence>
              {this.state.isLoading && (
                <motion.div
                  initial={{ opacity: 0, y: -70 }}
                  animate={{ opacity: 1, y: -0 }}
                  exit={{ opacity: 0, y: -120 }}
                  className="absolute -top-15 left-0 w-full transform -translate-x-1/2 flex justify-center"
                >
                  <BounceLoader
                    size={50}
                    color={"#372FD9"}
                    loading={this.state.isLoading}
                  />
                </motion.div>
              )}
            </AnimatePresence>

            <div className="relative">
              <AnimatePresence>
                {this.state.isLoading && (
                  <motion.div
                    initial={{ opacity: 1 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="absolute w-full h-full bg-white"
                  ></motion.div>
                )}
              </AnimatePresence>

              <iframe
                onLoad={this.hideSpinner}
                title="Contact Form"
                className={`airtable-embed airtable-dynamic-height ${
                  this.state.isLoading ? "" : "shadow-xl"
                }`}
                src="https://airtable.com/embed/shrAWVVgFRshsiBRg"
                frameBorder={0}
                width="100%"
                height="100%"
              ></iframe>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default contact
